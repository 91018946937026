<template>
    <div class="photoContent">
        <div id="bakcnull">
        </div>
        <div id="bigImg">
            <img :src="bigUrl" alt="">
            <span>
                    <svg class="icon" aria-hidden="true" @click="closemask">
                            <use xlink:href="#icon-cha"></use>
                    </svg>
            </span>
        </div>
        <div class="frindsss">
            <div class="outer">
                <div class="photo-img" v-for="(value,item) in photos" @click="openmask(value.photoUrl)">
                    <div class="mask">
                        <div class="brief">
<!--                            <p>-->
<!--                                <span>{{value.photoName}}</span>-->
<!--                            </p>-->
                            <br>
                            <p>
                                <span>{{value.photoDt}}</span>&nbsp;
                                <span>{{value.photoAddress}}</span>&nbsp;
                            </p>
                            <br>
                            <p>
                                <span>{{value.photoDesc}}</span>
                            </p>
                        </div>
                    </div>
                    <img  :src="value.photoUrl" alt=""/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "photoContent",
        data(){
            return{
                bigUrl:"http://img.fuqz.cc/054f3e04-c9f6-45c3-a122-2a8a959404fb",
                photos:[],
            }
        },
        methods:{
            /* 打开遮罩层*/
            openmask(url){
                this.bigUrl=url;
                document.getElementById("bakcnull").style.display = "block";
                document.getElementById("bigImg").style.display = "block"
            },
            /* 关闭遮罩层*/
            closemask(){
                document.getElementById("bakcnull").style.display = "none";
                document.getElementById("bigImg").style.display = "none"
            },
            /* 照片列表 */
            async photoList(){
                const res  = await this.$home.photoList()
                this.photos = res.data
            }
        },
        created() {
            this.photoList()
            this.$store.commit("updateRotation",false)
            /* 隐藏左侧 */
            this.$store.commit("updateLeftShow",true)
        }
    }
</script>

<style scoped>
</style>
